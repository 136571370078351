var d = Object.defineProperty;
var p = (s, a, t) => a in s ? d(s, a, { enumerable: !0, configurable: !0, writable: !0, value: t }) : s[a] = t;
var r = (s, a, t) => p(s, typeof a != "symbol" ? a + "" : a, t);
import { l as g } from "./chunks/index.js";
import { setLoginFlowState as w, fetcher as f, getUTMParams as u, whenPrerenderingActivated as y, toSlug as m } from "@vgno/utils";
const l = {
  dinepenger: "dp",
  vg: "vg"
};
class T extends HTMLElement {
  constructor() {
    super();
    r(this, "contentId", this.dataset.contentId || "unknown");
    r(this, "contentType", this.dataset.contentType);
    r(this, "deviceType", this.dataset.deviceType);
    r(this, "hasPreRenderedContent", this.hasChildNodes());
    r(this, "newsroom", this.dataset.newsroom || "vg");
    r(this, "stripe", null);
    this.onLoad = this.onLoad.bind(this);
  }
  createLoginUrl(t) {
    var e;
    const n = new URL(window.location.href);
    return n.searchParams.set("utm_source", "stripe"), t != null && t.contentName && n.searchParams.set("utm_medium", t.contentName), t != null && t.campaign && n.searchParams.set("utm_campaign", t.campaign), t != null && t.segmentName && n.searchParams.set("utm_term", t.segmentName), (e = window.Identity) == null ? void 0 : e.loginUrl({
      state: w(n.toString())
    });
  }
  async fetchStripeData() {
    try {
      const t = `https://cm.vg.no/v2/${l[this.newsroom] || "vg"}/stripe`, n = await f(t, { credentials: "include" });
      if (n.status === 204) return;
      if (n.status !== 200)
        throw new Error(`Failed to fetch stripe data: ${n.status}`);
      const e = u(window.location.toString(), !0);
      this.stripe = g.createSalesposter(n, {
        contentProvider: l[this.newsroom] || "vg",
        contentType: this.contentType,
        deviceType: this.deviceType,
        paywallType: "stripe",
        contentId: this.contentId,
        document,
        window,
        utmTagOverrides: e ? {
          utm_campaign: e.campaign,
          utm_content: e.content,
          utm_medium: e.medium,
          utm_source: e.source,
          utm_term: e.terms,
          utm_url: e.url
        } : void 0,
        generateLoginUrl: (c) => this.createLoginUrl(c)
      }), this.onLoad();
    } catch (t) {
      console.error("Failed to generate stripe", t);
    }
  }
  onLoad() {
    if (!this.stripe) return;
    const t = this.stripe.salesposter.html.replace(
      /{{article_id}}/g,
      this.contentId
    );
    this.renderContent(t), this.trackView();
  }
  renderContent(t) {
    var c;
    if (!this.shadowRoot) return console.error("No shadow root found");
    const e = new DOMParser().parseFromString(t, "text/html");
    this.shadowRoot.innerHTML = "", e.querySelectorAll("style").forEach((o) => {
      var i;
      (i = this.shadowRoot) == null || i.appendChild(o.cloneNode(!0));
    }), Array.from(e.body.childNodes).forEach((o) => {
      var i;
      (i = this.shadowRoot) == null || i.appendChild(o.cloneNode(!0));
    }), (c = this.shadowRoot) == null || c.querySelectorAll("script").forEach((o) => {
      var h;
      const i = document.createElement("script");
      o.src ? i.src = o.src : i.textContent = o.textContent, (h = o.parentNode) == null || h.replaceChild(i, o);
    });
  }
  async trackView() {
    var o, i;
    await y();
    const t = window.nmTrackerSync;
    if (!t) {
      console.error(
        "News Media Pulse not initialized. Ensure NM tracker (v7+) is loaded."
      );
      return;
    }
    const n = document.documentElement.dataset.pageType, e = (o = this.stripe) == null ? void 0 : o.salesposter;
    if (!e) return console.error("No stripe data found");
    const c = [
      "salesposter",
      m(e.posterType || "stripe"),
      m(e.contentName || "unknown"),
      e.campaign && m(e.campaign),
      e.segmentName && m(e.segmentName),
      e.abTestGroup && m(e.abTestGroup)
    ].filter(Boolean);
    t.trackViewUIElement({
      origin: (i = this.stripe) == null ? void 0 : i.pulseOrigin,
      object: {
        name: e.contentName || "Salesposter Stripe",
        elementType: e.posterType || "Salesposter - Stripe",
        id: c.join(":"),
        page: {
          id: this.contentId,
          url: window.location.href,
          type: n || "Page"
        },
        placement: { primaryPosition: 1 }
      }
    });
  }
  connectedCallback() {
    let t = null;
    if (this.hasPreRenderedContent)
      for (t = document.createDocumentFragment(); this.firstChild; )
        t.appendChild(this.firstChild);
    this.shadowRoot || (this.attachShadow({ mode: "open" }), t && this.shadowRoot.appendChild(t)), this.fetchStripeData();
  }
}
const S = () => {
  customElements.get("vg-stripe") || customElements.define("vg-stripe", T);
};
export {
  S as default
};
